:root{
    --theme-font-color:#293264;
    --btn-color:#06124d;
    --pure-white: #fff;
    --grey-clr: rgba(154, 154, 154, 0.779);
    --checked-radio: #D6DBF5;
}

.start-quiz-btn{
    padding: 10px 20px;
    margin-top: 5vh;
    margin-bottom: auto;

    border: 2px solid var(--btn-color);
    color: var(--btn-color);
    font-weight: 600;
    background: var(--pure-white);
    font-size: 1rem;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    cursor: pointer;
}

.start-quiz-btn::before{
    content: '';
    position: absolute;
    top: 6px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% - 12px);
    background: var(--pure-white);
    transition: 0.25s ease-in-out;
    transform: scaleY(1);
}
.start-quiz-btn:hover::before{
    transform: scaleY(0);
}
.start-quiz-btn::after{
    content: '';
    position: absolute;
    top: -4px;
    left: 6px;
    height: calc(100% + 8px);
    width: calc(100% - 12px);
    background: var(--pure-white);
    transition: 0.25s ease-in-out;
    transform: scaleX(1);
}
.start-quiz-btn:hover::after{
    transform: scaleX(0);
}

.start-quiz-btn > span{
    z-index: 3;
    position: relative;
}