:root{
  --theme-font-color:#293264;
  --btn-color:#4D5B9E;
  --pure-white: #fff;
  --grey-clr: rgba(154, 154, 154, 0.779);
  --checked-radio: #D6DBF5;
}

*{
  margin: 0;
  box-sizing: border-box;
  font-family: Karla, sans-serif;
}

main{
  position: relative;
  margin: 0 auto;
  max-width: 550px ;
  height: 100vh;
  text-align: center;
  /* background: url("./assets/images/blob_1.svg") no-repeat left; */
}

main::after{
  content: url("../images/blob_1.svg");
  position: absolute;
  top: 0;
  right: 0;
  aspect-ratio: 1;
  z-index: -1;
}
main::before{
  content: url("../images/blob_2.svg");
  position: absolute;
  bottom: 0;
  left: 0;;
  aspect-ratio: 1;
  z-index: -1;
}

.flex--col{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex--row{
  display: flex;
  justify-content: center;
  align-items: center;
}
.startPage,.quizPage{
  box-shadow: 0 0 9px 1px rgb(171 171 171 / 50%);
}

.startPage > h1{
  margin-top: auto;
  font-size: 3rem;
}

@media only screen and (max-device-width: 480px){
  .start-quiz-btn{
    font-size: 1.4rem;

    border: none;

  }
  .start-quiz-btn::before , .start-quiz-btn::after{
    display: none;
  }
  *{
    transform: scale(0.95);
  }
  #root,.app,main,body,html{
    transform: scale(1);
  }

  .quizPage > h1 {
    font-size: 1.4rem;
  }
  form > p{
    width: unset;
  }
}



/* --------- form quiz page ---------- */

form{
  color: var(--theme-font-color);
}

.quizPage > p{
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 1.2rem;
  width: 80%;
  text-align: justify;
  color: var(--theme-font-color);
}


.radio{
  background-color: var(--pure-white);
  color: var(--theme-font-color);
  /* display: block; */
  /* width: ; */
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid var(--btn-color);
  border-radius: 20px;
  border-bottom: 2px solid var(--btn-color)

}
.radio:not(:first-of-type){
  margin-top: 0.4rem;
}

.red {
  background-color: rgb(193, 37, 37);
  color: var(--pure-white);
  border: none;
}
.green{
  background-color: rgb(54, 129, 54);
  color: var(--pure-white);
  border: none;
}

.score {
  position: absolute;
  top: 2%;
  right: 5%;
  display: block;
  font-style: italic;
  font-size: 0.9rem;
}

.hiScore {
  position: absolute;
  top: 2%;
  left: 5%;
  display: block;
  font-style: italic;
  font-size: 0.9rem;
}

