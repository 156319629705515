.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
    background: #fff;
    z-index: 20;
}

ion-icon{
    font-size: 4rem;
    animation: animate 5s;
}
@keyframes animate {
    from{
        transform: rotate(0deg);
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    to{
        transform: rotate(720deg);
        opacity: 0;
    }
}
